import { COLLECT_PAYMENT_INITIAL_STATE } from "@store/constant.store";
import { StateCreator } from "zustand";

type TCollectPayment = typeof COLLECT_PAYMENT_INITIAL_STATE;

type IPayment = {
  collectPayment: TCollectPayment;
};

type TPaymentAction = {
  _updateCollectPaymentKey: (key: keyof typeof COLLECT_PAYMENT_INITIAL_STATE, value: any) => void;
  _updateBulkCollectPayment: (obj: Partial<TCollectPayment>) => void;
  _updateCollectPaymentData: (obj: TCollectPayment) => void;
  resetPayment: () => void;
};

type TPaymentSlice = IPayment & TPaymentAction;

const paymentSlice: StateCreator<TPaymentSlice, [], [], TPaymentSlice> = set => ({
  collectPayment: COLLECT_PAYMENT_INITIAL_STATE,
  _updateBulkCollectPayment: changes => set(state => ({ ...state, collectPayment: { ...state.collectPayment, ...changes } })),
  _updateCollectPaymentData: paymentData => set(state => ({ ...state, collectPayment: paymentData })),
  _updateCollectPaymentKey: (key, value) => set(state => ({ ...state, collectPayment: { ...state.collectPayment, [key]: value } })),
  resetPayment: () => set(state => ({ ...state, collectPayment: COLLECT_PAYMENT_INITIAL_STATE })),
});

export type { TPaymentSlice };
export { paymentSlice };
