import { Events, PushNotification } from "@red.health/red-hooks";

export const redEvent = new Events({
  clevertap: {
    accountId: import.meta.env.VITE_CLEVERTAP_ACCOUNT_ID,
  },
});

export const redPushNotification = new PushNotification({
  firebase: {
    apiKey: import.meta.env.VITE_APP_API_KEY,
    authDomain: import.meta.env.VITE_APP_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_APP_PROJECT_ID,
    storageBucket: import.meta.env.VITE_APP_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_APP_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_APP_APP_ID,
    measurementId: import.meta.env.VITE_APP_MEASUREMENT_ID,
  },
});
