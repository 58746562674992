import { useRedStore } from "@store/store";
import { debounce } from "lodash";

// This is a hook file which is between UI and Store + Network.
/**
 *
 * Case 1: User just update the store
 * Case 2: User update and make the API Call -> API call resolve and update the store
 *
 * So this action hook can do both
 */

const dispatch = (cb: any) => debounce(() => cb(), 50);

export default function useAppAction() {
  const _updateAuthMeta = useRedStore(state => state._updateAuthMeta);

  const __updateAuthInfo = (email: string, token: string) => {
    const payload = {
      userEmail: email || "",
      token: token || "",
    };
    dispatch(_updateAuthMeta(payload));
  };

  return { __updateAuthInfo };
}
