import { ILoginUser } from "@config/types";
import { LOGIN_USER_INITIAL_STATE } from "@store/constant.store";
import { removeNullAndEmptyStrings } from "@utils";
import { StateCreator } from "zustand";

interface IUserSlice {
  user: ILoginUser;
  source: any[];
  sourceMap: any;
  _updateSourceMap: (sourceMap: any) => void;
  _updateSource: (source: any[]) => void;
  _updateUser: (user: ILoginUser) => void;
  removeUser: () => void;
}

const userSlice: StateCreator<IUserSlice, [], [], IUserSlice> = set => ({
  user: LOGIN_USER_INITIAL_STATE,
  source: [],
  sourceMap: {},
  _updateSourceMap: (sourceMap: any) => set(state => ({ ...state, sourceMap })),
  _updateSource: source => set(state => ({ ...state, source })),
  _updateUser: userInfo => {
    const processedValue = { ...removeNullAndEmptyStrings(userInfo) };
    return set(state => ({ user: { ...state.user, ...processedValue } }));
  },
  removeUser: () => set(() => ({ user: LOGIN_USER_INITIAL_STATE })),
});

export type { IUserSlice };
export { userSlice };
