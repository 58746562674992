import { Flex, Box, FormControl, FormLabel, Input, Checkbox, Stack, Button, Heading, Text, useColorModeValue, Highlight, Center, Slide, ScaleFade } from "@chakra-ui/react";
import { useSendOtpUserMutation, useVerifyOtpUserMutation } from "../../../hooks/api/useUserApi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "react-use";
import { showSuccessToast } from "../../../utils";
import { _event_send_otp, _event_verify_otp, pageView } from "@config/event";

export default function Login() {
  const navigate = useNavigate();
  const [value, setValue] = useLocalStorage("token", "");
  const [_, setEmailToLocal] = useLocalStorage("login-email", "");
  const [state, setState] = useState({
    email: "",
    otp: "",
    otpToken: "",
  });

  const [disableInput, setDisableInput] = useState({
    email: false,
    otp: false,
  });

  const onSendSMSSuccess = (response: any) => {
    const { data } = response;
    showSuccessToast(data?.message);
    setState(prev => ({ ...prev, otpToken: data?.data?.otpToken }));
    setDisableInput(prev => ({ ...prev, email: true }));
  };

  const onVerifyOtpSuccess = (data: any) => {
    showSuccessToast("OTP Verified Successfully.");
    setValue(data?.data?.data?.token);
    setEmailToLocal(state.email);
    navigate("/");
    window.location.reload();
  };

  const { mutateAsync, ...sendOTPStatus } = useSendOtpUserMutation(onSendSMSSuccess);
  const { mutateAsync: verifyMutatedAsync, ...verifyOTPStatus } = useVerifyOtpUserMutation(onVerifyOtpSuccess);

  const onOTPVerify = () => {
    _event_verify_otp(state.email, state.otp);
    verifyMutatedAsync({ ...state, email: state?.email?.toLocaleLowerCase() });
  };

  const onSendClick = () => {
    _event_send_otp(state.email);
    mutateAsync({ email: state.email?.toLocaleLowerCase() });
  };

  // Auto navigate to Dashboard
  useEffect(() => {
    pageView("Login Page");
    if (value && value?.length > 0) {
      navigate("/");
    }
  }, []);

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={useColorModeValue("gray.50", "gray.800")}>
      <Stack mx={"auto"} maxW={"lg"} py={5} h={"100vh"} pos={"relative"} overflow={"hidden"} w={"full"}>
        <Box flex={1} pos={"relative"}>
          <Center flexDirection={"column"} h={"full"}>
            <Box pos={"relative"}>
              <Box h={250} w={"auto"} mb={5} pos={"relative"} zIndex={"dropdown"} className="fade_in_left_animation">
                <img src="/assets/ambulances/red-amb.webp" alt="" />
              </Box>
              <Box zIndex={"base"} w={"550px"} h={"550px"} bottom={0} left={"-17%"} bgGradient="linear(to-r, orange.500, red.500)" pos={"absolute"} borderRadius={"full"}></Box>
            </Box>

            <Box h={25} w={200} mb={5}>
              <img src="/assets/logo/red_health.png" alt="" />
            </Box>
          </Center>
        </Box>
        <Box flex={1} rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"sm"} px={6} overflow={"scroll"} py={12}>
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel fontSize={"sm"} fontWeight={"semibold"}>
                Email address
              </FormLabel>
              <Input disabled={disableInput.email} type="email" onChange={e => setState(prev => ({ ...prev, email: e.target.value }))} />
            </FormControl>
            {state.otpToken && (
              <FormControl id="otp">
                <FormLabel fontSize={"sm"} fontWeight={"semibold"}>
                  OTP
                </FormLabel>
                <Input type="number" disabled={disableInput.otp} onChange={e => setState(prev => ({ ...prev, otp: e.target.value }))} />
              </FormControl>
            )}
            <Stack spacing={10}>
              <Stack direction={{ base: "column", sm: "row" }} align={"start"} justify={"space-between"}>
                <Checkbox borderRadius={"full"} type="checkbox">
                  <Text fontSize={"sm"} fontWeight={"normal"}>
                    Remember me
                  </Text>
                </Checkbox>
              </Stack>
              <Button
                isLoading={sendOTPStatus.isLoading || verifyOTPStatus.isLoading}
                onClick={state.otpToken ? onOTPVerify : onSendClick}
                bg={"red.400"}
                color={"white"}
                _hover={{
                  bg: "red.500",
                }}
              >
                {state.otpToken ? "Verify OTP" : "Get OTP"}
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
