import { IAddons, IPrice } from "@config/types";
import { INITIAL_PRICE_STATE } from "@store/constant.store";
import { StateCreator } from "zustand";
import { current } from "immer";

type TAddonPrice = typeof INITIAL_PRICE_STATE;
type TAddonPriceKeys = keyof TAddonPrice;
type TAddonPriceStore = {
  price: IPrice;
  addons: IAddons[];
  selectedAddons: IAddons[];
  addonsMap: Record<string, IAddons>;
  priceUpdateCount: number;
  constToOwner: number;
  saatiPricingList: [];
};

type TAddonPriceStoreAction = {
  _updatePrice: (price: IPrice) => void;
  _updateAddons: (addons: IAddons[]) => void;
  _addAddons: (addon: IAddons) => void;
  _removeAddons: (addon: IAddons) => void;
  _changeAddonPrice: (addon: IAddons) => void;
  _setSelectedAddons: (selectedAddons: IAddons[]) => void;
  _setConstToOwner: (cost: any) => void;
  _setSaatiPricingList: (saatiPricingList: any) => void;
  _restAddons: () => void;
  _resetPrice: () => void;
};

type TAddonPriceSlice = TAddonPriceStore & TAddonPriceStoreAction;

const addonPriceSlice: StateCreator<TAddonPriceSlice, [], [], TAddonPriceSlice> = set => ({
  price: INITIAL_PRICE_STATE,
  constToOwner: 0,
  addons: [],
  addonsMap: {},
  saatiPricingList: [],
  selectedAddons: [],
  priceUpdateCount: 0,
  _setSaatiPricingList: saatiPricingList => set(state => ({ ...state, saatiPricingList: saatiPricingList })),
  _setConstToOwner: cost => set(state => ({ ...state, constToOwner: cost })),
  _addAddons: (addon: IAddons) =>
    set(state => {
      const _currentAddon = state.selectedAddons.find(itm => itm.key === addon.key);
      const currentAddon = _currentAddon && current(_currentAddon);
      if (!currentAddon) {
        const payload = { ...addon, quantity: 1 };
        return { ...state, selectedAddons: [...state.selectedAddons, payload] };
      } else if (currentAddon.quantity && currentAddon.quantity < addon.maxqty) {
        const modifiedArr = state.selectedAddons.map((itm: any) => (itm.key === addon.key ? { ...itm,price: itm.price ? (itm.price / (itm.quantity || 1)) * (itm.quantity + 1) : '', quantity: itm.quantity + 1 } : itm));
        return { ...state, selectedAddons: modifiedArr };
      } else {
        return state;
      }
    }),
  _removeAddons: (addon: IAddons) =>
    set(state => {
      const _currentAddon = state.selectedAddons.find(itm => itm.key === addon.key);
      const currentAddon = _currentAddon && current(_currentAddon);
      if (currentAddon && currentAddon?.quantity && currentAddon.quantity > 1) {
        const modifiedArr = state.selectedAddons.map((itm: any) => (itm.key === addon.key ? { ...itm, price: itm.price ? (itm.price / (itm.quantity || 1)) * (itm.quantity - 1) : '', quantity: itm.quantity - 1 } : itm));
        return { ...state, selectedAddons: modifiedArr };
      } else if (currentAddon && currentAddon.quantity === 1) {
        return { ...state, selectedAddons: state.selectedAddons.filter((itm: any) => itm.key !== currentAddon.key) };
      } else {
        return state;
      }
    }),
    _changeAddonPrice: (addon: IAddons) => set(state => {
      const _currentAddon = state.selectedAddons.find(itm => itm.key === addon.key);
      const currentAddon = _currentAddon && current(_currentAddon);
      if (currentAddon) {
        const modifiedArr = state.selectedAddons.map((itm: any) => (itm.key === addon.key ? { ...itm, price: addon.price, type: addon.type } : itm));
        return { ...state, selectedAddons: modifiedArr };
      } else {
        return state;
      }
    }),
  _setSelectedAddons: (selectedAddons: IAddons[]) => set(state => ({ ...state, selectedAddons })),
  _updatePrice: price => set(state => ({ ...state, price, priceUpdateCount: state.priceUpdateCount + 1 })),
  _updateAddons: addons => set(state => ({ ...state, addons, addonsMap: addons.reduce((acc, itm) => ({ ...acc, [itm.id]: itm }), {}) })),
  _restAddons: () => set(state => ({ ...state, selectedAddons: [] })),
  _resetPrice: () => set(() => ({ price: INITIAL_PRICE_STATE, addons: [], addonsMap: {}, selectedAddons: [], priceUpdateCount: 0 })),
});

export type { TAddonPriceSlice, TAddonPriceKeys, TAddonPrice };
export { addonPriceSlice };
