import { IAddons, IPrice } from "@config/types";
import { INITIAL_ANALYTICS_STATE, INITIAL_FILTER_STATE, INITIAL_PRICE_STATE } from "@store/constant.store";
import { StateCreator } from "zustand";
import { current } from "immer";

type TFilterState = typeof INITIAL_FILTER_STATE;
type TFilterStateKeys = keyof TFilterState;

type TOrderMetrics = typeof INITIAL_ANALYTICS_STATE.orderMetrics;
type TLeadEnquiryMetrics = typeof INITIAL_ANALYTICS_STATE.leadEnquiry;
type TInCompleatMetrics = typeof INITIAL_ANALYTICS_STATE.incompleteMetrics;

type TAnalyticsState = typeof INITIAL_ANALYTICS_STATE;
// type TFilterStateKeys = keyof TFilterState;

type TFilterStateStore = {
  filter: TFilterState;
  analytics: TAnalyticsState;
};

type TFilterAction = {
  _restFilter: () => void;
  _updateStartDateFilter: (payload: number) => void;
  _updateEndDateFilter: (payload: number) => void;
  _updateAnalytics: (payload: TAnalyticsState) => void;
  _updateAnalyticsOrderMetrics: (payload: TOrderMetrics) => void;
  _updateAnalyticsLeadEnquiry: (payload: TLeadEnquiryMetrics) => void;
  _updateAnalyticsIncompleteMetrics: (payload: TInCompleatMetrics) => void;
};

type TFilterSlice = TFilterStateStore & TFilterAction;

const filterSlice: StateCreator<TFilterSlice, [], [], TFilterSlice> = set => ({
  filter: INITIAL_FILTER_STATE,
  analytics: INITIAL_ANALYTICS_STATE,
  _updateStartDateFilter: payload => set(state => ({ ...state, filter: { ...state.filter, startDate: { ...state.filter.startDate, value: payload } } })),
  _updateEndDateFilter: payload => set(state => ({ ...state, filter: { ...state.filter, endDate: { ...state.filter.endDate, value: payload } } })),
  _updateAnalytics: payload => set(state => ({ ...state, analytics: payload })),
  _updateAnalyticsOrderMetrics: (payload: TOrderMetrics) => set(state => ({ ...state, analytics: { ...state.analytics, orderMetrics: payload } })),
  _updateAnalyticsLeadEnquiry: (payload: TLeadEnquiryMetrics) => set(state => ({ ...state, analytics: { ...state.analytics, leadEnquiry: payload } })),
  _updateAnalyticsIncompleteMetrics: (payload: TInCompleatMetrics) => set(state => ({ ...state, analytics: { ...state.analytics, incompleteMetrics: payload } })),
  _restFilter: () => set(state => ({ ...state, filter: INITIAL_FILTER_STATE })),
});

export type { TFilterSlice, TFilterStateKeys, TFilterStateStore };
export { filterSlice };
