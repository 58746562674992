import { INearbyVehicle, IOnePVehicleInterface } from "@config/types";
import { StateCreator } from "zustand";

type IFleet = {
  eligibleVehicle: IOnePVehicleInterface[];
  inEligibleVehicle: INearbyVehicle[];
  searchedVehicles: any[];
  searchKey: string;
};

type TFleetAction = {
  _updateEligibleVehicles: (payload: IOnePVehicleInterface[]) => void;
  _updateInEligibleVehicles: (payload: INearbyVehicle[]) => void;
  _updateSearchVehicles: (payload: []) => void;
  _updateSearchKey: (key: string) => void;
};

type TFleetSlice = IFleet & TFleetAction;

const fleetSlice: StateCreator<TFleetSlice, [], [], TFleetSlice> = set => ({
  eligibleVehicle: [],
  inEligibleVehicle: [],
  searchedVehicles: [],
  searchKey: "",
  _updateSearchKey: changes => set(state => ({ ...state, searchKey: changes })),
  _updateSearchVehicles: changes => set(state => ({ ...state, searchedVehicles: changes })),
  _updateEligibleVehicles: changes => set(state => ({ ...state, eligibleVehicle: changes })),
  _updateInEligibleVehicles: changes => set(state => ({ ...state, inEligibleVehicle: changes })),
});

export type { TFleetSlice };
export { fleetSlice };
