import { ISelectedOutstanding } from "@config/types";
import { some } from "lodash";
import { StateCreator } from "zustand";

type IWallet = {
  selectedOutstanding: ISelectedOutstanding[];
};

type TWalletAction = {
  _insertOutstanding: (payload: ISelectedOutstanding) => void;
  _resetOutstanding: () => void;
};

type TWalletSlice = IWallet & TWalletAction;

const walletSlice: StateCreator<TWalletSlice, [], [], TWalletSlice> = set => ({
  selectedOutstanding: [],
  _insertOutstanding: payload =>
    set(state => {
      return {
        ...state,
        selectedOutstanding: some(state?.selectedOutstanding, ["transactionId", payload.transactionId])
          ? state.selectedOutstanding?.filter(itm => itm.transactionId !== payload.transactionId)
          : [...state.selectedOutstanding, payload],
      };
    }),
  _resetOutstanding: () => set(state => ({ ...state, selectedOutstanding: [] })),
});

export type { TWalletSlice };
export { walletSlice };
