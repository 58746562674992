// src/services/userApi.ts
import { axiosInstance } from "../axios-interceptor";
import request from "../request";

const urls = {
  sendOtp: "/auth/email/sendOTP",
  verifyOTP: "/auth/email/verifyOTP",
  testToken: "/auth/testToken",
  mdmUser: "/data/users?email=",
};

// Interface for the User object (adjust according to your API response)
interface User {
  id: number;
  name: string;
  email: string;
}

// Function to fetch the list of users from the server
export const fetchUsers = (): Promise<User[]> => {
  return request<User[]>("GET", "/users")
    .then((response) => response.data)
    .catch((error) => {
      // You can handle errors here, or simply propagate them back to the calling component
      throw error;
    });
};

export interface ISendLoginOtp {
  email: string;
}

export interface IVerifyLoginOtp {
  email: string;
  otp: string;
  otpToken: string;
}

export const sendLoginOtp = (body: ISendLoginOtp) => {
  const response = request("POST", urls.sendOtp, body);
  return response;
};

export const verifyLoginOtp = (body: IVerifyLoginOtp) => {
  const response = request("POST", urls.verifyOTP, body);
  return response;
};

export const refetchAuthToken = (token: string) => {
  axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const response = request(
    "GET",
    urls.testToken,
    {},
    {
      headers: {
        Authorization: token,
      },
    }
  );
  return response;
};

export const getMDMUser = (email: string) => {
  const response = request("GET", `${urls.mdmUser}${email}`);
  return response;
};
