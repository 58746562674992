import { ILoginUser } from "@config/types";
import { version } from "../../package.json";
import dayjs from "dayjs";
import { progress } from "framer-motion";
import { PLATFORM_OBJ } from "@config/constant";

export const LOGIN_USER_INITIAL_STATE: ILoginUser = {
  id: "",
  userId: "",
  userType: "",
  type: "",
  employeeId: "",
  role: "",
  primaryRole: "",
  entityType: "",
  name: "",
  fullName: "",
  username: "",
  phoneNo: "",
  primaryPhoneNo: "",
  altPhoneNo: "",
  companyId: "",
  companyName: "",
  companyType: "",
  roleIds: [""],
  email: "",
  walletId: "",
  address: "",
  locality: "",
  district: "",
  location: "",
  pincode: "",
  city: "",
  country: "",
  aadhaarNo: "",
  panNo: "",
  availability: false,
  isReadyForLongTrip: false,
  drivingLicensceNo: "",
  dedicatedToClientId: "",
  eligibleRoles: [""],
  status: "",
  createdAt: "",
  updatedAt: "",
  hospitalmanagerId: "",
  lastLoginAt: "",
};

export const APP_STORE_INITIAL_STATE = {
  version,
  currentPageName: "",
  showLiveEventDrawer: false,
};

export const AUTH_INITIAL_STATE = {
  token: "",
  userEmail: "",
};

export const COLLECT_PAYMENT_INITIAL_STATE = {
  amount: 0,
  totalAmount: 0,
  advanceAmount: 0,
  minAmount: 0,
  selectedMode: "total",
  isPriceMandatory: false,
  progress: 100,
  isFullyPaid: false,
};

export const INITIAL_ANALYTICS_STATE = {
  orderMetrics: {
    created: 0,
    completed: 0,
    incomplete: 0,
    scheduled: 0,
  },
  leadEnquiry: {
    leads: 0,
    enquiries: 0,
  },
  incompleteMetrics: {
    cancelled: 0,
    unfulfilled: 0,
    pendingPayment: 0,
  },
};

export const INITIAL_FILTER_STATE = {
  ambulanceType: {
    key: "ambulanceType",
    name: "Ambulance Type",
    value: [
      {
        key: "als",
        name: "ALS",
        icon: "",
      },
      {
        key: "bls",
        name: "BLS",
        icon: "",
      },
    ],
  },
  startDate: {
    key: "startDate",
    name: "Start Date",
    value: dayjs().startOf("day").unix() * 1000,
  },
  endDate: {
    key: "endDate",
    name: "End Date",
    value: dayjs().endOf("day")?.unix() * 1000,
  },
};

export const INITIAL_PRICE_STATE = {
  billToClient: false,
  price: null,
  distance: "0",
  distanceType: "",
  vehicleType: "",
  vehicleFormulaType: "",
  minMaxPrice: {
    min: null,
    max: null,
  },
};

export const INITIAL_ORDER_TEMPLATE_STATE = {
  templateType: "STANDARD",
};

export const INITIAL_PATIENT_PROFILE = {
  gender: "",
  name: "",
  mobile: "",
  weight: 0,
  age: 0,
  height: {
    feet: 0,
    inches: 0,
  },
};

export const INITIAL_WAYPOINTS = [{}, {}];

export const INITIAL_PATIENT_META = {
  orderClassification: "",
  city: "",
  distance: "",
};

export const INITIAL_VEHICLE_NEARBY_STATE = {
  als: {
    eta: null,
    price: null,
    quantity: 0,
  },
  alsStandard: {
    eta: null,
    price: null,
    quantity: 0,
  },
  alsSupreme: {
    eta: null,
    price: null,
    quantity: 0,
  },
  alsElite: {
    eta: null,
    price: null,
    quantity: 0,
  },
  bls: {
    eta: null,
    price: null,
    quantity: 0,
  },
  blsElite: {
    eta: null,
    price: null,
    quantity: 0,
  },
  blsStandard: {
    eta: null,
    price: null,
    quantity: 0,
  },
  blsSupreme: {
    eta: null,
    price: null,
    quantity: 0,
  },
};

export const INITIAL_ONEP_VEHICLE_TYPE = {
  entityId: "",
  entityType: "",
  daasEntityId: "",
  lat: 0,
  lng: 0,
  timestamp: 0,
  meta: {},
  distance: 0,
  pickupEta: 0,
  pickupDistance: 0,
  ambulanceId: "",
  ambulanceType: "",
  fleetId: "",
  ambulanceNumberPlate: "",
  ambulanceSpec: "",
  pilotId: "",
  pilotName: "",
  pilotMobileNum: "",
  pilotStatus: false,
  pilotEmail: null,
  helperId: null,
  helperName: null,
  helperMobileNum: null,
  helperStatus: null,
  helperEmail: null,
  paramedicId: "",
  paramedicName: "",
  paramedicMobileNum: "",
  paramedicStatus: false,
  paramedicEmail: null,
};

export const INITIAL_ORDER_STATE = {
  department: "",
  overridenType: "",
  isSaathiFleet: false,
  _hm_can_assign: false,
  _only_location_update: false,
  _is_more_then_x_km: false,
  overwriteFare: 0,
  billToClient: false,
  ipPatientNumber: "",
  isCaseOnHold: false,
  mobile: "",
  orderId: "",
  totalFare: 0,
  originSource: {
    source: "",
    platform: "",
    version: PLATFORM_OBJ?.version,
  },
  feedback: {},
  dispatchType: "",
  generatedBy: "",
  caseType: "",
  dispatchNow: true,
  bookingType: "LEAD",
  instructionForSquad: "",
  city: "",
  distance: -1,
  productType: "",
  scheduledDdtm: 0,
  serviceType: "",
  dispatchSourcedAt: "",
  addonsFare: 0,
  entityRequired: [],
  dispatchUpdatedAt: null,
  documents: [],
  requestedFor: {
    weight: 0,
    height: {
      feet: 0,
      inches: 0,
    },
    age: 0,
    gender: "",
    name: "",
    mobile: "",
    medicalIssue: {
      reason: [],
      meta: {},
    },
  },
  fareAgreed: 0,
  costToOwner: 0,
  stanplusMargin: 0,
  requestedBy: {
    mobile: "",
    name: "",
  },
  fleetTypeRequested: "",
  waypoints: [{}, {}],
  assignmentType: "MANUAL",
  orderStatus: "draft",
  scheduleDate: "",
  scheduleTime: "",
  minFare: 0,
  maxFare: 0,
  isRequestorPatient: false,
  ambulanceFare: 0,
  needMoreInfo: "",
  cancelReasons: [""],
  addons: [],
  allMedicalReasons: [""],
  customPayment: 0,
  assignmentDetails: {},
  selectedOrigin: "",
  isOverridenPrice: false,
  overridenComments: "",
  overwriteBillTo: "",
  isOverrideEnquiry: false,
  selectedCity: { label: "", value: "" },
  initialServiceType: "",
  attributedTo: "RED",
  _isSwap: false,
  _isServiceSectionValid: false,
  _isDispatchModeValid: false,
  _isWaypointValid: false,
  _isPatientDetailsValid: false,
  _isVehicleDetailsValid: false,
  _isAddonValid: false,
  _isReadyToDispatch: false,
  _needAdvancePayment: false,

  isLamaDamaSelected: false,

  isCovidCase: false,
  orderClassification: "",
  systemOrderClassification: "",
  _selectedVehicleType: {
    value: "",
    label: ",",
    icon: ",",
    fare: 0,
    isDisabled: false,
    variant: [],
    parent: ",",
    message: ",",
  },
  _selectedVehicleVariant: "",
  lastDispatchedBy: "",
  serviceLocation: [{}],
  serviceLocationDuration: {
    from: "",
    to: "",
  },
};

export const MIN_PRICE_PERCENTAGE = 20;
export const PRICE_MANDATORY_CITY_CODE_LIST = ["BLR", "JPR", "IDR", "AMD", "HYD", "MOHL", "RAI", "KOL", "GGN", "DLH", "FDB", "GZB", "NOI"];
