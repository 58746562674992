import { IServiceLocationDuration, WaypointsEntity } from "@config/types";
import { INITIAL_ORDER_STATE, INITIAL_ORDER_TEMPLATE_STATE, INITIAL_PATIENT_META, INITIAL_PATIENT_PROFILE, INITIAL_VEHICLE_NEARBY_STATE, INITIAL_WAYPOINTS } from "@store/constant.store";
import { StateCreator, StateSelector } from "zustand";

type TOrder = typeof INITIAL_ORDER_STATE;
type TConfig = typeof INITIAL_ORDER_TEMPLATE_STATE;
type TVehicle = typeof INITIAL_VEHICLE_NEARBY_STATE;
export type TLocationType = "pickup" | "dropoff";
interface ILocType {
  type: TLocationType;
  address: any;
}

type TOrderKeys = keyof TOrder | "version";
type TOrderStore = {
  order: TOrder;
  selectedOrder: TOrder | null;
  localOrderChanges: TOrder;
  templateConfig: TConfig;
  nearbyVehicle: TVehicle;
  patientProfile: typeof INITIAL_PATIENT_PROFILE;
  patientWaypoints: typeof INITIAL_WAYPOINTS;
  patientMeta: typeof INITIAL_PATIENT_META;
  temptingCaseId: string;
  locationTypeSelection: ILocType;
};

type TOrderStoreAction = {
  _updateLocationType: (type: any) => void;
  _resetPatientInfo: () => void;
  _updateOrder: (order: TOrder) => void;
  _removeOrder: () => void;
  _updateLocalCaseProperty: (key: TOrderKeys, value: any) => void;
  _updateBulkLocalOrderChanges: (obj: Partial<TOrder>) => void;
  _updateBulkOrderChanges: (obj: Partial<TOrder>) => void;
  _updateWaypoint1: (waypoint1: WaypointsEntity) => void;
  _updateServiceLocation: (serviceLocation: WaypointsEntity) => void;
  _updateWaypoint2: (waypoint2: WaypointsEntity) => void;
  _updateMedicalEmergency: (reasons: object) => void;
  _updateAge: (age: number) => void;
  _updatePatientName: (name: string) => void;
  _updatePatientHeight: (height: { feet: number; inches: number }) => void;
  _updatePatientWeight: (weight: number) => void;
  _updateGender: (gender: object) => void;
  _updateRequestedInfo: (requestedFor: object, requestedBy: object) => void;
  _updateRequestFor: (requestedFor: object) => void;
  _updateTemplateType: (type: string) => void;
  _updateWaypoints: (waypoint1: WaypointsEntity, waypoint2: WaypointsEntity) => void;
  _updateVehicles: (vehicle: TVehicle) => void;
  _resetVehicleInfo: () => void;
  _resetOrder: () => void;
  _resetLocalOrderChanges: () => void;
  _updateServiceLocationDuration: (duration: IServiceLocationDuration) => void;
  _updateOverwriteFare: (price: number) => void;
  _updateSelectedOrder: (order: any) => void;
  _initiatePatientProfile: (info: any, waypoints: any, meta: any) => void;
  _updatePatientProfile: (key: keyof typeof INITIAL_PATIENT_PROFILE, value: any) => void;
  _updateTemptingCaseId: (orderId: string) => void;
  _resetTemptingCaseId: () => void;
};

type TOrderSlice = TOrderStore & TOrderStoreAction;

const orderSlice: StateCreator<TOrderSlice, [], [], TOrderSlice> = set => ({
  order: INITIAL_ORDER_STATE,
  localOrderChanges: INITIAL_ORDER_STATE,
  templateConfig: INITIAL_ORDER_TEMPLATE_STATE,
  nearbyVehicle: INITIAL_VEHICLE_NEARBY_STATE,
  selectedOrder: null,
  patientProfile: INITIAL_PATIENT_PROFILE,
  patientWaypoints: INITIAL_WAYPOINTS,
  patientMeta: INITIAL_PATIENT_META,
  temptingCaseId: "",
  locationTypeSelection: { type: "pickup", address: {} },
  _updateLocationType: type => set(state => ({ ...state, locationTypeSelection: type })),
  _updateTemptingCaseId: (orderId: string) => set((state: any) => ({ ...state, temptingCaseId: orderId })),
  _resetTemptingCaseId: () => set((state: any) => ({ ...state, temptingCaseId: "" })),
  _resetPatientInfo: () => set(() => ({ patientProfile: INITIAL_PATIENT_PROFILE, patientWaypoints: INITIAL_WAYPOINTS, patientMeta: INITIAL_PATIENT_META })),
  _initiatePatientProfile: (info, waypoints, meta) => set((state: any) => ({ ...state, patientWaypoints: waypoints, patientProfile: info, patientMeta: meta })),
  _updatePatientProfile: (key, value) => set((state: any) => ({ ...state, patientProfile: { ...state.patientProfile, [key]: value } })),
  _updateSelectedOrder: orderData => set(() => ({ selectedOrder: orderData })),
  _updateVehicles: vehicles => set(state => ({ ...state, nearbyVehicle: vehicles })),
  _resetVehicleInfo: () => set(() => ({ nearbyVehicle: INITIAL_VEHICLE_NEARBY_STATE })),
  _updateTemplateType: type => set(state => ({ ...state, templateConfig: { ...state.templateConfig, templateType: type } })),
  _updateOrder: order => set(() => ({ order })),
  _updatePickupAddress: (address: any) =>
    set((state: any) => {
      return { ...state, localOrderChanges: { ...state.localOrderChanges, waypoints: [address, state.localOrderChanges.waypoints[1]] } };
    }),
  _updateBulkLocalOrderChanges: changes => set(state => ({ ...state, localOrderChanges: { ...state.localOrderChanges, ...changes } })),
  _updateBulkOrderChanges: changes => set(state => ({ ...state, order: { ...state.order, ...changes } })),
  _removeOrder: () => set(state => ({ ...state, order: INITIAL_ORDER_STATE })),
  _updateServiceLocationDuration: duration =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, serviceLocationDuration: duration },
    })),
  _updateServiceLocation: location =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, serviceLocation: [location] },
    })),
  _updateWaypoints: (waypoint1, waypoint2) =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, waypoints: [waypoint1, waypoint2] },
    })),
  _updateWaypoint1: waypoint1 =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, waypoints: [waypoint1, state.localOrderChanges.waypoints[1]] },
    })),
  _updateWaypoint2: waypoint2 =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, waypoints: [state.localOrderChanges.waypoints[0], waypoint2] },
    })),
  _updateMedicalEmergency: reasons =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, medicalIssue: { reason: reasons } } },
    })),
  _updateAge: age =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, age } },
    })),
  _updateOverwriteFare: price =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, overwriteFare: price },
    })),
  _updatePatientName: name =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, name } },
    })),
  _updateRequestFor: (requestedFor: any) => {
    const { height, ...rest } = requestedFor;
    return set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, ...rest, height: { feet: height.feet || 0, inches: height.inches || 0 } } },
    }));
  },

  _updateRequestedInfo: (requestedFor, requestedBy) =>
    set((state: any) => ({
      ...state,
      localOrderChanges: {
        ...state.localOrderChanges,
        requestedFor: { ...state.localOrderChanges.requestedFor, ...requestedFor },
        requestedBy: { ...state.localOrderChanges.requestedBy, ...requestedBy },
      },
    })),

  _updatePatientHeight: height =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, height } },
    })),
  _updatePatientWeight: weight =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, weight } },
    })),
  _updateGender: gender =>
    set((state: any) => ({
      ...state,
      localOrderChanges: { ...state.localOrderChanges, requestedFor: { ...state.localOrderChanges.requestedFor, gender } },
    })),
  _updateLocalCaseProperty: (key, value) => set((state: any) => ({ ...state, localOrderChanges: { ...state.localOrderChanges, [key]: value } })),
  _resetOrder: () => set((state: any) => ({ ...state, order: INITIAL_ORDER_STATE })),
  _resetLocalOrderChanges: () => set((state: any) => ({ ...state, localOrderChanges: INITIAL_ORDER_STATE })),
});

export const selectOrderSlice: StateSelector<TOrderSlice, TOrder> = state => state.order;

export type { TOrderSlice, TOrderKeys, TOrder };
export { orderSlice };
