import { APP_STORE_INITIAL_STATE, AUTH_INITIAL_STATE } from "@store/constant.store";
import { StateCreator } from "zustand";

type IAppStore = {
  app: typeof APP_STORE_INITIAL_STATE;
  auth: typeof AUTH_INITIAL_STATE;
};

type TAppStoreAction = {
  _updateAppKey: (key: keyof typeof APP_STORE_INITIAL_STATE, value: any) => void;
  _updateCurrentPageTitle: (title: string) => void;
  _updateLiveEventDrawer: (isOpen: boolean) => void;
  _updateAuthMeta: (payload: any) => void;
};

type TAppSlice = IAppStore & TAppStoreAction;

const appSlice: StateCreator<TAppSlice, [], [], TAppSlice> = set => ({
  app: APP_STORE_INITIAL_STATE,
  auth: AUTH_INITIAL_STATE,
  _updateAuthMeta: payload => set(state => ({ ...state, auth: payload })),
  _updateAppKey: (key, value) => set(state => ({ ...state, app: { ...state.app, [key]: value } })),
  _updateCurrentPageTitle: title => set(state => ({ ...state, app: { ...state.app, currentPageName: title } })),
  _updateLiveEventDrawer: isOpen => set(state => ({ ...state, app: { ...state.app, showLiveEventDrawer: isOpen } })),
});

export type { TAppSlice };
export { appSlice };
