import { useMutation, UseMutationResult, useQuery } from "react-query";
import { AxiosResponse, AxiosError } from "axios";
import { getMDMUser, ISendLoginOtp, IVerifyLoginOtp, refetchAuthToken, sendLoginOtp, verifyLoginOtp } from "../../api/service/user";
import { useLocalStorage } from "react-use";
import { ILoginUser, IMDMUserData, ITokenData } from "../../config/types";
import { isValidObject, showErrorToast } from "../../utils";
import { useAppStore } from "../../store/AppStore";
import { useRedStore } from "@store/store";
import useAppAction from "@store/action-hook/useAppAction";
import { useNavigate } from "react-router-dom";
import { redEvent } from "lib/red-hooks";

const messageToken = localStorage.getItem("messageToken");

const useSendOtpUserMutation = (onSuccess?: (data: AxiosResponse<any>) => void): UseMutationResult<AxiosResponse<any>, AxiosError, ISendLoginOtp, undefined> => {
  // Use the useMutation hook to create a new user
  return useMutation<AxiosResponse<any>, AxiosError, ISendLoginOtp, undefined>(sendLoginOtp, {
    onSuccess,
    onError: (err: any) => {
      const message = err.response?.data?.message;
      showErrorToast(message || "Failed to send OTP 😢");
    },
  });
};

const useVerifyOtpUserMutation = (onSuccess?: (data: AxiosResponse<any>) => void): UseMutationResult<AxiosResponse<any>, AxiosError, any, undefined> => {
  return useMutation<AxiosResponse<any>, AxiosError, IVerifyLoginOtp, undefined>(verifyLoginOtp, {
    onSuccess,
    onError: (err: any) => {
      const message = err.response?.data?.message;
      showErrorToast(message || "Wrong OTP 🤷");
    },
  });
};

const useAuthTokenQuery = ({ ...rest }): any => {
  const authToken = useRedStore(state => state.auth.token);
  const [userToken] = useLocalStorage("token", null);
  const token = authToken || userToken || "";

  const { __updateAuthInfo } = useAppAction();

  if (!token) {
    if (!window.location.pathname?.includes("auth")) {
      window.location.pathname = "/auth/logout";
    }
  }

  return useQuery(["authToken", token], () => refetchAuthToken(token), {
    enabled: !!token,
    onSuccess: (data: any) => {
      const tokenUser: ITokenData = data?.data;
      __updateAuthInfo(tokenUser?.email, token);
      rest?.onSuccess && rest.onSuccess(data);
    },
  });
};

const useUserFromMDMQuery = ({ ...rest }): any => {
  // *  Getting email from local storage
  const [userEmail] = useLocalStorage("login-email", "");
  const stateEmail = useRedStore(state => state.auth.userEmail);
  const email = stateEmail || userEmail;

  // * Query to fetch auth user
  return useQuery([`login-email-${email}`, email], () => getMDMUser(email || ""), { enabled: !!email, ...rest });
};

const useLoginUser = () => {
  const { ...rest } = useAuthTokenQuery({ onSuccess: onTokenUserSetup });
  const { ...others } = useUserFromMDMQuery({ onSuccess: onMdmUserSetup });
  const loginUser = useRedStore(state => state.user);
  const setLoginDetails = useAppStore(state => state.setLoginDetails);
  const updateUser = useRedStore(state => state._updateUser);

  const _updateUser = (user: any) => {
    updateUser(user);
    if (user) {
      const payload = {
        Name: user?.name,
        Identity: user?.id,
        Email: user?.email,
        Phone: user?.phoneNo ? `+91${user?.phoneNo}` : undefined,
        dedicatedToClientId: user.dedicatedToClientId,
        push_token: messageToken,
      };

      redEvent.identify(
        {
          userId: user.id,
          userDetails: payload,
        },
        ["clevertap"]
      );
    }
  };

  function onTokenUserSetup(data: any) {
    const tokenUser: ITokenData = data?.data;
    if (isValidObject(tokenUser)) {
      setLoginDetails(tokenUser);
      const user: ILoginUser = {
        name: tokenUser?.name || "",
        email: tokenUser?.email || "",
        eligibleRoles: tokenUser.roles,
        dedicatedToClientId: "",
        createdAt: tokenUser?.createdAt || "",
        lastLoginAt: tokenUser?.lastLoginAt || "",
      };
      if (!loginUser?.email || loginUser?.eligibleRoles?.length <= 0) _updateUser(user);
    }
  }

  function onMdmUserSetup(mdmUser: any) {
    const systemUser: IMDMUserData = mdmUser?.data?.data && mdmUser?.data?.data?.length > 0 ? mdmUser.data?.data[0] : {};

    if (isValidObject(systemUser)) {
      setLoginDetails(systemUser);
      _updateUser(systemUser);
    }
  }

  return { isLoading: rest?.isLoading || others?.isLoading };
};

export { useSendOtpUserMutation, useVerifyOtpUserMutation, useAuthTokenQuery, useUserFromMDMQuery, useLoginUser };
