import { create as CreateRedStore } from "zustand";
import { removeNullAndEmptyStrings } from "../utils";
import { IAppState, ILoginUser } from "@config/types";

interface IAppStateContainer {
  app: IAppState;
  loginUser: ILoginUser;
  setLoginDetails: (value: any) => void;
  setAppProps: (propertyName: keyof typeof initialState, value: string | number) => void;
  setUserProps: (propertyName: keyof typeof loginUserInitialState, value: any) => void;
}

const initialState: IAppState = {
  currentPageTitle: "Case Creation",
};

const loginUserInitialState: ILoginUser = {
  name: "",
  phoneNo: "",
  city: "",
  email: "",
  hospitalmanagerId: "",
  walletId: "",
  dedicatedToClientId: "",
};

export const useAppStore = CreateRedStore<IAppStateContainer>()(set => ({
  app: initialState,
  loginUser: loginUserInitialState,
  setAppProps: (property, value) => set(state => ({ app: { ...state.app, [property]: value } })),
  setLoginDetails: value => {
    const processedValue = { ...removeNullAndEmptyStrings(value) };
    return set(state => {
      const newState = { loginUser: { ...state.loginUser, ...processedValue } };
      // eslint-disable-next-line no-console
      return newState;
    });
  },
  setUserProps: (property, value) => set(state => ({ loginUser: { ...state.loginUser, [property]: value } })),
}));
