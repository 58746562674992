import { redEvent } from "lib/red-hooks";
const email = localStorage.getItem("login-email");

export const pageView = (pageName: string, obj?: object) => {
  const payload = {
    key: "PAGE_VIEW",
    action: {
      "Page Name": pageName,
      email: email,
    },
  };

  if (obj && Object.keys(obj)?.length > 0) {
    payload.action = { ...payload.action, ...obj };
  }
  redEvent.push(payload);
};

// Login Page

export const _event_send_otp = (email: any) => {
  redEvent.push({
    key: "LOGIN_PAGE_SEND_OTP_BUTTON_CLICKED",
    action: {
      email,
    },
  });
};

export const _event_verify_otp = (email: string, otp: string) => {
  redEvent.push({
    key: "LOGIN_PAGE_VERIFY_OTP_BUTTON_CLICKED",
    action: {
      email,
      otp,
    },
  });
};

// Case Listing
// export const clickOnMyCaseTab = (user: any) => {
//   redEvent.push({
//     key: "CLICK_ON_MY_CASE_TAB",
//     action: {
//       user,
//     },
//   });
// };

// export const clickOnAllCaseTab = (user: any) => {
//   redEvent.push({
//     key: "CLICK_ON_ALL_CASE_TAB",
//     action: {
//       user,
//     },
//   });
// };

export const clickOnCaseFilterOpen = () => {
  redEvent.push({
    key: "CASE_FILTER_OPEN_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

export const clickOnCaseFilterClose = () => {
  redEvent.push({
    key: "CASE_FILTER_CLOSE_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

export const clickOnApplyFilter = () => {
  redEvent.push({
    key: "CASE_FILTER_APPLY_FILTER_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

export const clickOnClearFilter = () => {
  redEvent.push({
    key: "CASE_FILTER_CLEAR_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

//  --------------- 1. Create Case Modal Events --------------

export const clickOnCaseCreateButton = () => {
  redEvent.push({
    key: "CASE_CREATE_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

export const clickOnCaseCreateModalClose = () => {
  redEvent.push({
    key: "CASE_CREATE_MODAL_CLOSE_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

export const clickOnCaseCreateModalSubmit = (data: any) => {
  redEvent.push({
    key: "CASE_CREATE_MODAL_SUBMIT_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ---------------- 2. CRM Page Events ----------------

export const openCaseTemplateModal = () => {
  redEvent.push({
    key: "CASE_TEMPLATE_MODAL_OPENED",
    action: {
      email: email,
    },
  });
};

export const closeCaseTemplateModal = () => {
  redEvent.push({
    key: "CASE_TEMPLATE_MODAL_CLOSED",
    action: {
      email: email,
    },
  });
};

export const clickOnCaseCreateButtonFromCRMPage = (data: any) => {
  redEvent.push({
    key: "SELECT_SERVICE_TYPE_AND_CREATE_CASE",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnCaseRecreateModalOpen = (data: any) => {
  redEvent.push({
    key: "CASE_RECREATE_MODEL_OPENED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnCaseRecreate = (data: any) => {
  redEvent.push({
    key: "CASE_RECREATE_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// export const clickOnCaseType = (data: any) => {
//   redEvent.push({
//     key: "CASE_TEMPLATE_MODAL_SELECTED_CASE_TYPE",
//     action: data
//       ? { ...data, email: email }
//       : {
//           email: email,
//         },
//   });
// };

// ----------------- 3. Case Enquiry Events ------------

export const clickOnCaseEnquiryButton = () => {
  redEvent.push({
    key: "CASE_ENQUIRY_BUTTON_CLICKED",
    action: {
      email: email,
    },
  });
};

// export const viewCaseEnquiry = (data: any) => {
//   redEvent.push({
//     key: "VIEW_CASE_ENQUIRY",
//     action: data
//       ? { ...data, email: email }
//       : {
//           email: email,
//         },
//   });
// };

// ----------------- 4. Cancel Order ------------

export const openCancelOrderModal = (data: any) => {
  redEvent.push({
    key: "CANCEL_ORDER_MODAL_OPENED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const closeCancelOrderModal = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CANCEL_ORDER_MODAL_CLOSED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnCancelOrderButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CANCEL_ORDER_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ----------------- 5. Case On Hold ------------

export const caseOnHoldModelOpen = (data?: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CASE_ON_HOLD_MODAL_OPENED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const caseOnHoldModelClose = (data?: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CASE_ON_HOLD_MODAL_CLOSED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const toggleOnHoldButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CASE_ON_HOLD_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ----------------- 6. Force Dispatch ------------

export const clickOnForceDispatchButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_FORCE_DISPATCH_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnForceDispatchModalClose = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_FORCE_DISPATCH_MODAL_CLOSED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnForceDispatchModalSubmit = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_FORCE_DISPATCH_MODAL_SUBMIT_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ----------------- 7. Case History - Contact Details ------------

export const clickOnContactEditButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CONTACT_EDIT_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnContactCallButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CONTACT_CALL_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const closePatientDetailsModal = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_PATIENT_DETAILS_MODAL_CLOSED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const toggleContactIsPatient = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CONTACT_IS_PATIENT_TOGGLED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const clickOnContactSaveButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CONTACT_SAVE_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const caseHistoryClickOnScheduledNowButton = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_SCHEDULED_NOW_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const caseHistoryConvertToNowFromScheduled = (data: any) => {
  redEvent.push({
    key: "CASE_HISTORY_CONVERT_TO_NOW_FROM_SCHEDULED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ----------------- 8. Create Case  ------------

// ----------------- Save as Enquiry ------------

export const createCaseSaveAsEnquiryModalOpen = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_SAVE_AS_ENQUIRY_MODAL_OPENED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseSaveAsEnquiryModalClose = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_SAVE_AS_ENQUIRY_MODAL_CLOSED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseSaveAsEnquiryButtonClicked = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_SAVE_AS_ENQUIRY_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

//------------------ top inland -----------------
export const createCaseSourceChangeTo = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_SOURCE_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseAttributionChangeTo = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_ATTRIBUTION_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseCityChangeTo = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_CITY_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseDispatchTypeSelect = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_DISPATCH_TYPE_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ------------------- waypoints -------------------

export const createCaseWaypointSelection = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_WAYPOINT_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseOpenGoogleMap = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_OPEN_GOOGLE_MAP",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseConfirmWaypointFromMap = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_CONFIRM_WAYPOINT_FROM_MAP",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseTogglePartnerSearch = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_TOGGLE_PARTNER_SEARCH",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseSwipeWaypoints = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_SWIPE_WAYPOINTS",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const overrideToLamaDamaToggle = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_OVERRIDE_TO_LAMA_DAMA",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseCovidCaseOverride = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_COVID_CASE_OVERRIDE",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// --- vehicle select ----

export const createCaseVehicleTypeSelect = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_VEHICLE_TYPE_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseVehicleVariantSelect = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_VEHICLE_VARIANT_SELECT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// --------- addons ---------
export const createCaseAddonButtonClick = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_ADDON_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseCloseAddons = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_CLOSE_ADDONS",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseSubmitAddons = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_SUBMIT_ADDONS",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCasePricingMode = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_PRICING_MODE",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseNegotiatePriceClick = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_NEGOTIATE_PRICE_CLICK",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseNegotiatePriceSubmit = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_NEGOTIATE_PRICE_SUBMIT",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const createCaseFormSubmitButtonClicked = (data: any) => {
  redEvent.push({
    key: "CREATE_CASE_FORM_SUBMIT_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// --------- Payments -----------
export const paymentsTotalAmountClick = (data: any) => {
  redEvent.push({
    key: "PAYMENTS_TOTAL_AMOUNT_CLICK",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const paymentsAdvanceAmountClick = (data: any) => {
  redEvent.push({
    key: "PAYMENTS_ADVANCE_AMOUNT_CLICK",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const paymentClickOnCollectOnline = (data: any) => {
  redEvent.push({
    key: "PAYMENT_COLLECT_ONLINE_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const paymentClickCollectCash = (data: any) => {
  redEvent.push({
    key: "PAYMENT_COLLECT_CASH",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const paymentClickOnSendPaymentLink = (data: any) => {
  redEvent.push({
    key: "PAYMENT_SEND_PAYMENT_LINK_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ---------- dispatch page ------------
export const dispatchPageViewScheduledNowModal = (data: any) => {
  redEvent.push({
    key: "DISPATCH_PAGE_VIEW_SCHEDULED_NOW_MODAL",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const dispatchPageScheduledButtonClicked = (data: any) => {
  redEvent.push({
    key: "DISPATCH_PAGE_SCHEDULED_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const dispatchPageViewHistoryButtonClicked = (data: any) => {
  redEvent.push({
    key: "DISPATCH_PAGE_VIEW_HISTORY_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const dispatchPageAmbulanceCardView = (data: any) => {
  redEvent.push({
    key: "DISPATCH_PAGE_AMBULANCE_CARD_VIEW",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const dispatchPageAssignAmbulance = (data: any) => {
  redEvent.push({
    key: "DISPATCH_PAGE_ASSIGN_AMBULANCE",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ---------------- Additional Details ----------------
export const additionalDetailsSubmitButtonClicked = (data: any) => {
  redEvent.push({
    key: "ADDITIONAL_DETAILS_SUBMIT_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// ----------------  Live Events -----------------
export const liveEventViewDetails = (data: any) => {
  redEvent.push({
    key: "LIVE_EVENT_VIEW_DETAILS",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

// --------------- Wallets ----------------
export const walletViewDetails = (data: any) => {
  redEvent.push({
    key: "WALLET_VIEW_DETAILS",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const walletPayNowButtonClicked = (data: any) => {
  redEvent.push({
    key: "WALLET_PAY_NOW_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const walletSendOtpButtonClicked = (data: any) => {
  redEvent.push({
    key: "WALLET_SEND_OTP_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};

export const walletVerifyAndCollectButtonClicked = (data: any) => {
  redEvent.push({
    key: "WALLET_VERIFY_AND_COLLECT_BUTTON_CLICKED",
    action: data
      ? { ...data, email: email }
      : {
          email: email,
        },
  });
};
