import { StateCreator, create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { IUserSlice, userSlice } from "@store/slice/user.slice";
import { devtools, subscribeWithSelector } from "zustand/middleware";

import { TOrderSlice, orderSlice } from "@store/slice/order.slice";
import { TAppSlice, appSlice } from "@store/slice/app.slice";
import { addonPriceSlice, TAddonPriceSlice } from "@store/slice/addon.price.slice";
import { TFilterSlice, filterSlice } from "@store/slice/order.filter.slice";
import { paymentSlice, TPaymentSlice } from "@store/slice/payment.slice";
import { fleetSlice, TFleetSlice } from "@store/slice/fleet.slice";
import { walletSlice, TWalletSlice } from "@store/slice/wallet.slice";

type TStorType = TOrderSlice & IUserSlice & TAppSlice & TAddonPriceSlice & TFilterSlice & TPaymentSlice & TFleetSlice & TWalletSlice;

export const useRedStore = create<TStorType>(
  devtools(
    subscribeWithSelector((...a) => ({
      ...immer<TOrderSlice>(orderSlice)(...a),
      ...immer<IUserSlice>(userSlice)(...a),
      ...immer<TAppSlice>(appSlice)(...a),
      ...immer<TAddonPriceSlice>(addonPriceSlice)(...a),
      ...immer<TFilterSlice>(filterSlice)(...a),
      ...immer<TPaymentSlice>(paymentSlice)(...a),
      ...immer<TFleetSlice>(fleetSlice)(...a),
      ...immer<TWalletSlice>(walletSlice)(...a),
    }))
  ) as StateCreator<any, [], []>
);

// export const useBoundStore = create<TStorType>(import.meta.env.VITE_ENVIRONMENT === "development" ? devtools(storeCreator) : storeCreator);
